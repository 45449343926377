import Vue, { CreateElement } from "vue";
import { Component, Watch, Ref } from "vue-property-decorator";
import { State } from "vuex-class";
import maplibre, { LngLat, LngLatBounds, Marker } from "maplibre-gl";
import jwt_decode from "jwt-decode";

import { RulerControl, CompassControl, ZoomControl } from "@prashis/maplibre-gl-controls";
import MapboxDraw, { DrawCreateEvent, DrawDeleteEvent } from "@gc/mapbox-gl-draw";

import { assetDbApiFactory, gigamapApiFactory, messages, TokenPayload } from "@/services";
import * as store from "@/store";
import MapLayersControl from "@/components/map-layers-control";
import MapLegend, { getRequestOptions } from "@/components/map-legend";
import SearchResults from "@/components/search-results";
import QueryPopup from "@/components/query-popup";
import { FeatureInfo, StyleConfig, MyConfig, QueryResponse, MyProperty } from "@/services/gigamap";
import { Feature, FeatureCollection } from "geojson";
import SphericalMercator from "@mapbox/sphericalmercator";
import { noop } from "@/components/search-input";
import StreetViewControl from "@/streetview-control";
import GigastoreControl from "@/gigastore-control";
import LocationToolControl from "@/location-tool-control";
import { apiUrl, getEnvironmentFromHostname } from "@/appconfig";
import {
    accessTokenFromStore,
    cacheRequests,
    isExternalUser,
    MAP_CACHE,
    setUnsavedChanges,
    showSnackbar,
} from "@/utils";

import { GeoJSONGeometry } from "wellknown";
import GigastoreFileUpload from "@/components/gigastore-file-upload";
import NewFeatureDialog from "@/components/asset-data-popup";
import ExportChangesControl from "@/export-changes-control";
import { ChangesetDialog } from "@/components/changeset-dialogue";
import centroid from "@turf/centroid";
import EditFeatureDialog from "@/components/edit-asset-popup";
import { vuetify } from "@/main";
import FreehandMode from "@/freehand-line";

// Tolerance radius in pixels when selecting assets on the map
const MAP_SELECT_TOLERANCE = 5;

// Authenticated map assets
const MAP_ASSETS = ["/config.json", "/style.json"];

// The limit of assets to be retrieved from the backend comes from
// the maximum number of drop tubes (32) and ducts (4) = 36
// that can be place in a single trench
const FEATURE_COUNT_RESULT_LIMIT = 36;

export interface QueryResults {
    [key: string]: FeatureInfo[];
}

interface LayerVisibility {
    name: string;
    visible: boolean;
}

interface Point {
    x: number;
    y: number;
}

interface MapHashParameters {
    coords?: LngLat;
    zoom?: number;
    // Extra layers to show in addition to the default
    extraLayers?: string[];
    // Hidden layers to override the default visibility
    hiddenLayers?: string[];
}

export async function precacheMapAssets(token: string) {
    const cache = await caches.open(MAP_CACHE);
    const API_URL = apiUrl();
    const requests = MAP_ASSETS.map(
        url => new Request(`${API_URL}${url}`, { headers: { Authorization: `Bearer ${token}` } }),
    );
    await cacheRequests(cache, requests);
}

async function fetchFeatureInfo(
    token: string,
    wmsUrl: URL,
    layers: string,
    latitude: number,
    longitude: number,
): Promise<QueryResults> {
    const sm = new SphericalMercator({});
    const [x, y] = sm.forward([latitude, longitude]);
    // Query using the center of a 100m * 100m bounding box
    const bbox = `${x - 50},${y - 50},${x + 50},${y + 50}`;
    const query = [
        "request=GetFeatureInfo",
        "version=1.3.0",
        `layers=${layers}`,
        `query_layers=${layers}`,
        "crs=EPSG:3857",
        "width=256",
        "height=256",
        "format=image/png",
        `bbox=${bbox}`,
        "info_format=application/json",
        "feature_count=5",
        "i=128",
        "j=128",
    ];
    const response = await fetch(`${wmsUrl.origin}${wmsUrl.pathname}?` + query.join("&"), getRequestOptions(token));

    if (response.status >= 400) {
        throw Error(response.statusText);
    }
    const featureCollection = (await response.json()) as FeatureCollection;
    let layer = "";
    switch (layers) {
        case "nps-polygons":
            layer = "NPS Polygons";
            break;
        case "pia-structures":
            layer = "PIA Structures";
            break;
        case "pia-ducts":
            layer = "PIA Ducts";
            break;
        default:
            layer = "Statutory Designations";
    }
    if (featureCollection.features.length === 0) {
        return {};
    }
    return {
        [layer]: featureCollection.features.map(feat => {
            return {
                properties: Object.entries(feat.properties ?? {}).map(([k, v]) => {
                    return { key: k, value: v };
                }),
            };
        }),
    };
}

function addDrawButton(innerHTML: string, title: string, onClick: () => any) {
    const btn = document.createElement("button");
    const drawGroupClass = "mapbox-gl-draw_ctrl-draw-btn";
    btn.className = drawGroupClass;
    btn.innerHTML = innerHTML;
    btn.title = title;
    btn.onclick = onClick;
    const topBtn = document.querySelector(`button.${drawGroupClass}`);
    const btnGroup = topBtn?.parentElement;
    if (btnGroup) {
        btnGroup.prepend(btn);
    }
}

function featuresToQueryResults(
    features: maplibregl.MapGeoJSONFeature[],
    layers: store.Layers,
    config: MyConfig,
): QueryResults {
    const results: QueryResults = {};
    const layerConfigByName = Object.fromEntries(config.layers.map(layer => [layer.name, layer]));
    const mapLayersToLayer: { [key: string]: store.Layer } = {};
    for (const layer of Object.values(layers)) {
        for (const layerId of layer.layers) {
            mapLayersToLayer[layerId] = layer;
        }
    }
    for (const feat of features) {
        if (Object.keys(feat.properties ?? {}).length === 0) {
            continue;
        }
        const layer = mapLayersToLayer[feat.layer.id];
        const name = layer.label;
        const propertiesConfig: { [key: string]: MyProperty } = Object.fromEntries(
            layerConfigByName[name].properties?.map(prop => [prop.key, prop]) ?? [],
        );
        // Don't include layers with no properties defined, e.g. 'Routes'
        if (Object.keys(propertiesConfig).length === 0) {
            continue;
        }
        if (!(name in results)) {
            results[name] = [];
        }
        const properties = Object.entries(feat.properties ?? {}).map(([key, value]) => {
            const propConfig: MyProperty = propertiesConfig[key];
            if (propConfig?.link_url_template && propConfig?.link_text_template) {
                const url = propConfig.link_url_template.replace("%%", value.toString());
                const text = propConfig.link_text_template.replace("%%", value.toString());
                return { key, value: { url, text } };
            }
            return { key, value };
        });
        results[name].push({ properties });
    }
    return results;
}

function featureInfoToFeature(feature: FeatureInfo, extraProps: { [key: string]: any } = {}): Feature {
    return {
        geometry: feature.geometry,
        properties: {
            id: feature.id,
            ...Object.fromEntries(feature.properties.map(attr => [attr.key, attr.value])),
            ...extraProps,
        },
        id: feature.id,
        type: "Feature",
    };
}

function featureToFeatureInfo(feature: Feature, excludeProps: string[] = []): FeatureInfo {
    return {
        geometry: feature.geometry,
        id: typeof feature.id === "string" ? feature.id : "",
        properties: Object.entries(feature.properties ?? {})
            .filter(([key]) => !excludeProps.includes(key))
            .map(([key, value]) => ({ key, value })),
    };
}

function queryResultsLength(results: QueryResults): number {
    return Object.values(results).reduce((a, features) => a + features.length, 0);
}

async function wmsFeatureInfo(
    token: string,
    style: maplibregl.StyleSpecification,
    sourceName: string,
    lng: number,
    lat: number,
): Promise<QueryResults> {
    const source = style.sources?.[sourceName];
    if (source && source.type === "raster" && source.tiles) {
        const wmsUrl = new URL(source.tiles[0]);
        const layers = wmsUrl.searchParams.get("layers");
        if (layers) {
            return await fetchFeatureInfo(token, wmsUrl, layers, lng, lat);
        }
    }
    return {};
}

function getTokenRoles(token?: string): string[] {
    if (!token) {
        return [];
    }
    const payload: TokenPayload = jwt_decode(token);
    return payload.roles;
}

function saveFeatures(features: FeatureCollection) {
    // TODO - Allow user to set filename
    const geojson = JSON.stringify(features);
    const dateToday = new Date().toISOString().slice(0, 10);
    const filename = `map-features-${dateToday}.geojson`;
    const objectUrl = URL.createObjectURL(new File([new Blob([geojson], { type: "application/json" })], filename));
    const downloadAnchor = document.createElement("a");
    downloadAnchor.href = objectUrl;
    downloadAnchor.download = filename;
    downloadAnchor.target = "_blank";
    downloadAnchor.click();
    URL.revokeObjectURL(objectUrl);
}

async function selectFile(): Promise<File | null | undefined> {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".json,.geojson";
    return new Promise(resolve => {
        input.addEventListener("change", e => {
            resolve(input.files?.item(0));
        });
        const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        input.dispatchEvent(evt);
    });
}

async function loadFeatures(): Promise<FeatureCollection | undefined> {
    const file = await selectFile();
    if (file) {
        const content = await file.text();
        return JSON.parse(content);
    }
}

async function getCommunitiesForFeature(token: string, feature: Feature): Promise<string[]> {
    if (feature.geometry.type !== "GeometryCollection") {
        const centerPoint = centroid(feature.geometry);
        try {
            const client = gigamapApiFactory(token);
            const response = await client.query(
                centerPoint.geometry.coordinates[0],
                centerPoint.geometry.coordinates[1],
                100,
            );
            return response.results.Communities.map(value => {
                const nameAttr = value.properties.find(attr => attr.key === "name");
                return (nameAttr?.value as string) ?? "";
            }).flatMap(item => item.split(","));
        } catch (e) {
            if (!(e instanceof TypeError)) {
                throw e;
            }
        }
    }
    return [];
}

function updateStyleUrls(mapStyle: maplibregl.StyleSpecification) {
    if (getEnvironmentFromHostname() === "local" && mapStyle.sprite && mapStyle.glyphs) {
        const spriteUrl = new URL(mapStyle.sprite);
        mapStyle.sprite = `${window.location.origin}${spriteUrl.pathname}`;
        const glyphsUrl = new URL(mapStyle.glyphs);
        mapStyle.glyphs = `${window.location.origin}${decodeURIComponent(glyphsUrl.pathname)}`;
    }
}

async function getCablePathFromGigamap(
    token: string,
    designId: string,
    servicePointId: string,
): Promise<string | undefined> {
    try {
        const client = gigamapApiFactory(token);
        const response = await client.getCablePathBetweenAssets(designId, servicePointId);
        return response;
    } catch (e) {
        if (!(e instanceof TypeError)) {
            throw e;
        }
    }
    return;
}

const emptyFeature = {
    type: "Feature",
    geometry: {
        type: "Point",
        coordinates: [0, 0],
    },
    properties: {},
};

@Component
export default class Map extends Vue {
    map?: maplibregl.Map;
    mapStyle?: maplibregl.StyleSpecification;
    config?: MyConfig;
    markers?: Marker[];
    defaultVisibility?: { [key: string]: boolean };
    rulerControl?: RulerControl;
    locationToolControl?: LocationToolControl;
    gigastoreUploadDialogVisible: boolean;
    gigastoreUploadMarkerDesignId: string | undefined;
    mapDraw?: MapboxDraw;
    exportChangesControl?: ExportChangesControl;
    newFeatureDialogVisible: boolean;

    @Ref("newFeatureDialog") readonly newFeatureDialog!: NewFeatureDialog;
    @Ref("changesetDialog") readonly changesetDialog!: ChangesetDialog;
    @Ref("editFeatureDialog") readonly editFeatureDialog!: EditFeatureDialog;
    @State((s: store.State) => s.featureEdits) featureEdits: { [key: string]: FeatureInfo };
    @State((s: store.State) => s.layers) layers: store.Layers;
    @State((s: store.State) => s.searchResults?.results) searchResults?: QueryResults;
    @State((s: store.State) => s.searchResultsVisible) searchResultsVisible: boolean;
    @State((s: store.State) => s.splitFrameVisible) splitFrameVisible: boolean;
    @State((s: store.State) => s.splitPanelURL) splitPanelURL: string;
    @State((s: store.State) => s.gigastoreUploadDialogVisible) gigastoreUploadDialogVisibleState: boolean;
    @State((s: store.State) => s.gigastoreUploadMarkerPosition) gigastoreUploadMarkerPosition:
        | [number, number]
        | undefined;
    @State((s: store.State) => s.cablePathVisible) cablePathVisible: boolean;

    data() {
        return {
            mapStyle: undefined,
            config: undefined,
            gigastoreUploadDialogVisible: false,
            gigastoreUploadMarkerDesignId: undefined,
            mapDraw: undefined,
            exportChangesControl: undefined,
            newFeatureDialogVisible: false,
        };
    }

    async editFeatureAtPoint(p: Point) {
        if (this.mapDraw) {
            const featureIds = this.mapDraw.getFeatureIdsAt(p);
            const tok = accessTokenFromStore(this.$store) ?? "";
            if (featureIds.length > 0) {
                const feature = this.mapDraw.get(featureIds[0]);
                if (feature && this.exportChangesControl) {
                    const communities = await getCommunitiesForFeature(tok, feature);
                    this.newFeatureDialog.open(feature, this.mapDraw, this.exportChangesControl, communities, 2);
                }
            }
        }
    }

    async mounted() {
        const token = accessTokenFromStore(this.$store);
        const client = await gigamapApiFactory(token ?? "");
        const config = (this.config = await client.getConfig());
        const mapStyle = await client.getStyleConfig();
        updateStyleUrls(mapStyle);
        this.mapStyle = mapStyle;
        precacheMapAssets(token ?? "");
        this.defaultVisibility = this.getDefaultVisibility(mapStyle);
        const layerConfig: store.Layers = {};
        config.layers.forEach(layer => {
            if (!layer.layers) {
                return;
            }
            layerConfig[layer.layers[0]] = {
                visible: this.defaultVisibility?.[layer.layers[0]] ?? false,
                layers: layer.layers,
                properties: layer.properties || [],
                label: layer.name,
                source: layer.table,
                group: layer.group,
            };
        });

        const mapOptions: maplibregl.MapOptions = {
            container: "map", // container id
            style: mapStyle,
            transformRequest: (url: string) => {
                return {
                    url,
                    headers: { Authorization: "Bearer " + accessTokenFromStore(this.$store) },
                };
            },
            // Limit bounds to those defined on https://epsg.io/27700
            // Note: The latitude range is larger to allow the map to center correctly at the
            // default zoom
            maxBounds: [
                [-10, 49.79],
                [4, 60.94],
            ],
        };

        const mapHashParams = this.parseMapHash();
        if (mapHashParams.coords && mapHashParams.zoom) {
            mapOptions.center = mapHashParams.coords;
            mapOptions.zoom = mapHashParams.zoom;
        }
        for (const layerName of mapHashParams.extraLayers ?? []) {
            layerConfig[layerName].visible = true;
        }
        for (const layerName of mapHashParams.hiddenLayers ?? []) {
            layerConfig[layerName].visible = false;
        }

        const map = (this.map = new maplibre.Map(mapOptions));
        (window as any).map = map; // to make testing in the browser easier
        map.addControl(new ZoomControl());
        map.addControl(new CompassControl());
        map.addControl(new maplibre.ScaleControl({}));
        const geolocate = new maplibre.GeolocateControl({
            positionOptions: { enableHighAccuracy: true },
            trackUserLocation: true,
        });
        map.addControl(geolocate);
        map.addControl(new maplibre.FullscreenControl({}));
        map.addControl(new StreetViewControl());
        map.addControl(new GigastoreControl(this.$store));
        this.rulerControl = new RulerControl({ font: ["Open Sans Regular"] });
        this.rulerControl.button.node.title = "Measure Distance";
        // @ts-ignore
        map.addControl(this.rulerControl);
        this.locationToolControl = new LocationToolControl();
        map.addControl(this.locationToolControl);
        const userRoles = getTokenRoles(token);

        if (config.edit_roles.some(role => userRoles.includes(role))) {
            try {
                const assetsClient = assetDbApiFactory(token ?? "");
                const cabAreas = await assetsClient.getCabinetAreas();
                store.setCabinetAreas(this.$store, cabAreas);
            } catch (e) {
                // Catch network errors when offline or if the user does not have access to asset-db
                if (!(e instanceof TypeError || (e instanceof Response && [403, 401].includes(e.status)))) {
                    throw e;
                }
            }
            this.mapDraw = new MapboxDraw({
                boxSelect: false,
                controls: {
                    point: true,
                    polygon: true,
                    trash: true,
                    line_string: true,
                    combine_features: false,
                    uncombine_features: false,
                },
                modes: {
                    ...MapboxDraw.modes,
                    draw_freehand: FreehandMode,
                },
            });
            // @ts-ignore
            map.addControl(this.mapDraw, "bottom-left");
            this.exportChangesControl = new ExportChangesControl();
            map.addControl(this.exportChangesControl, "bottom-left");
            addDrawButton(
                `
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                  </svg>
                `,
                "Freehand Line",
                // @ts-ignore
                () => this.mapDraw?.changeMode("draw_freehand"),
            );
            // Fetch list of cab-areas from asset-db
            // const assetDbClient = assetDbApiFactory(token ?? "");
        }

        map.on("click", this.onClick);
        map.on("moveend", this.onMoveEnd);
        map.on("dragstart", this.onDragStart);
        map.on("dragend", this.onDragEnd);
        map.on("rotatestart", this.onDragStart);
        map.on("rotateend", this.onDragEnd);
        map.on("load", () => {
            store.setLayers(this.$store, layerConfig);
            this.onLayerVisibilityChange(this.layerVisibility);
            // Source added to display cable path
            if (map.getSource("cablePathSource") == null) {
                map.addSource("cablePathSource", { type: "geojson", data: emptyFeature });
                map.addLayer({
                    id: "cablePathLayer",
                    type: "line",
                    source: "cablePathSource",
                    layout: {
                        "line-join": "round",
                        "line-cap": "round",
                    },
                    paint: {
                        "line-color": "#ff0000",
                        "line-width": 3,
                    },
                });
            }
        });
        map.on("draw.create", async (e: DrawCreateEvent) => {
            const tok = accessTokenFromStore(this.$store) ?? "";
            if (e.features.length > 0 && this.mapDraw && this.exportChangesControl) {
                const communities = await getCommunitiesForFeature(tok, e.features[0]);
                this.newFeatureDialog.open(e.features[0], this.mapDraw, this.exportChangesControl, communities);
            }
        });
        map.on("draw.delete", (e: DrawDeleteEvent) => {
            if (this.mapDraw) {
                const features = this.mapDraw.getAll();
                if (features.features.length === 0 && Object.keys(this.featureEdits).length === 0) {
                    this.exportChangesControl?.setEnabled(false, false);
                }
            }
        });
        map.on("export-changes", () => {
            if (this.mapDraw && this.exportChangesControl) {
                this.changesetDialog.open(this.mapDraw.getAll(), this.mapDraw, this.exportChangesControl);
            }
        });
        map.on("save-changes", () => {
            if (this.mapDraw) {
                const features = this.mapDraw.getAll();
                const updatedFeatures = Object.values(this.featureEdits).map(feature =>
                    featureInfoToFeature(feature, { update: true }),
                );
                saveFeatures({ type: "FeatureCollection", features: [...features.features, ...updatedFeatures] });
                setUnsavedChanges(false);
            }
        });
        map.on("load-features", async () => {
            if (this.mapDraw) {
                const features = await loadFeatures();
                if (features) {
                    const newFeats = [];
                    const updatedFeats = [];
                    for (const feat of features.features) {
                        if (feat.properties?.update) {
                            updatedFeats.push(featureToFeatureInfo(feat));
                        } else {
                            newFeats.push(feat);
                        }
                    }
                    this.mapDraw.set({ type: "FeatureCollection", features: newFeats });
                    store.setFeatureEdits(this.$store, updatedFeats);
                }
            }
        });

        map.on("dblclick", ev => this.editFeatureAtPoint(ev.point));

        // Support touch and hold gesture for editing new features.
        let touchTimeout: number | undefined = undefined;
        map.on("touchstart", ev => {
            touchTimeout = setTimeout(() => this.editFeatureAtPoint(ev.point), 500);
        });
        map.on("touchend", () => {
            if (touchTimeout !== undefined) {
                clearTimeout(touchTimeout);
            }
        });

        messages.$on("edit-feature", (source: string, feature: FeatureInfo) => {
            if (this.config) {
                this.editFeatureDialog.open(feature, this.config, source);
            }
        });
        messages.$on("open-sld", (feature: FeatureInfo) => {
            const baseUrl = window.location.origin;

            if (feature && feature.properties) {
                let cab = feature.properties.find(item => item.key === "name");
                if (cab) {
                    let canbinet_name = cab.value;
                    const componentUrl = `${baseUrl}/sld/?cabinet_name=${canbinet_name}`;
                    window.open(componentUrl, "_blank");
                }
            }
        });
        messages.$on("new-feature-edit", () => {
            const tok = accessTokenFromStore(this.$store) ?? "";
            this.exportChangesControl?.setEnabled(true, isExternalUser(tok));
        });
        messages.$on("search", (term: string, nonce: number) => this.doSearch(term, nonce));

        const splitPanelFrame = this.$refs.splitPanelFrame as HTMLIFrameElement;
        splitPanelFrame.addEventListener("load", () => {
            // TODO: Support AD based auth here
            if (this.splitPanelURL && this.$store.state.authVersion !== "AUTH_V2") {
                // Send the token to the any other UI frame so that a login prompt is not shown
                let accessToken = accessTokenFromStore(this.$store);
                splitPanelFrame.contentWindow?.postMessage(accessToken, window.location.origin);
            }
        });

        messages.$on("open-split-panel", (feature: FeatureInfo) => {
            if (feature && feature.properties) {
                store.setSplitFrameVisibility(this.$store, true);
                store.setSplitPanelURL(this.$store, feature.properties[1].value.url);
            }
        });

        messages.$on("show-cable-path", (designId: string, servicePointId: string) => {
            this.showCablePath(designId, servicePointId);
        });
        messages.$on("hide-cable-path", () => this.hideCablePath());
    }

    render(h: CreateElement) {
        return (
            <div style="height: 100%; display:flex;">
                <div
                    id="splitPanelContainer"
                    style={`flex:1; display: ${
                        this.splitFrameVisible ? "flex" : "none"
                    }; flex-direction: column; background-color:${
                        window.matchMedia("(prefers-color-scheme: dark)") ? "#121212" : "white"
                    }`}
                >
                    <div
                        id="splitPanelFrameClose"
                        style={`color: ${
                            window.matchMedia("(prefers-color-scheme: dark)") ? "white" : "#121212"
                        }; width:100%; background-color:#857676; float:left`}
                        onClick={this.closeSplitPanel}
                    >
                        <b style="padding: 2px 10px 2px 2px;float: right;">x</b>
                    </div>
                    <iframe
                        ref="splitPanelFrame"
                        id="splitPanelFrame"
                        name="splitPanelFrame"
                        src={`${this.splitFrameVisible ? this.splitPanelURL : ""}`}
                        style={`width:100%; height:100%;${this.splitPanelURL ? "" : "display: none"}`}
                    />
                </div>
                <MapLayersControl />
                {this.mapStyle && this.config && (
                    <MapLegend legendConfig={this.config.legend} mapStyle={this.mapStyle} />
                )}
                <SearchResults
                    onPanTo={(event: any) => {
                        this.map!.fitBounds(event.bounds, { maxZoom: 19, padding: 20 });
                        this.addWayleavesHighlighter(event);
                    }}
                />
                <NewFeatureDialog ref="newFeatureDialog" />
                <EditFeatureDialog ref="editFeatureDialog" />
                <ChangesetDialog ref="changesetDialog" />
                <div id="map" style="flex: 1;width:100%" />
                {this.gigastoreUploadMarkerPosition && this.gigastoreUploadMarkerDesignId && (
                    <GigastoreFileUpload
                        v-model={this.gigastoreUploadDialogVisible}
                        designId={this.gigastoreUploadMarkerDesignId}
                        geometry={{ type: "Point", coordinates: this.gigastoreUploadMarkerPosition }}
                    ></GigastoreFileUpload>
                )}
            </div>
        );
    }

    async doSearch(text: string, nonce: number): Promise<void> {
        if (text) {
            if (this.map) {
                const client = await gigamapApiFactory(accessTokenFromStore(this.$store) ?? "");
                const location = this.map.getCenter();
                const searchQuery = client.search(text, location.lat, location.lng);
                const addressQuery = client.locationSearch(text, location.lat, location.lng);
                // @ts-ignore
                const result = await Promise.any([searchQuery, addressQuery]);
                let searchResponse: QueryResponse = { results: {} };
                if (result instanceof Array) {
                    searchResponse = await searchQuery;
                    searchResponse.results.Addresses = result;
                } else {
                    searchResponse = result;
                    // If there are any asset results, don't wait for the address search to complete
                    if (Object.keys(searchResponse.results).filter(val => val !== "Services").length === 0) {
                        const features = await addressQuery;
                        searchResponse.results.Addresses = features;
                    }
                }
                store.setSearchResults(this.$store, { nonce, ...searchResponse });
                this.$router.replace({ query: { q: text }, hash: this.$route.hash }).catch(noop);
            }
        } else {
            store.clearSearchResults(this.$store);
            this.$router.replace({ hash: this.$route.hash }).catch(noop);
        }
    }

    closeSplitPanel() {
        store.setSplitFrameVisibility(this.$store, false);
        store.setSplitPanelURL(this.$store, "");
    }

    async showCablePath(designId: string, servicePointId: string): Promise<void> {
        const token = accessTokenFromStore(this.$store) ?? "";
        let cablePath;
        try {
            cablePath = await getCablePathFromGigamap(token, designId, servicePointId);
        } catch (e) {
            if (e instanceof Response && e.status == 404) {
                showSnackbar(`This service point does not have a valid cable route back to the cabinet,
                please speak to the design team to get this corrected`);
            }
            if (e instanceof Response && e.status == 400) {
                showSnackbar(`Something went wrong while fetching the cable route back to the cabinet from this service point.
                Please speak to the design team to get this corrected.
                Error context: ${(await e.json()).detail}`);
            }
        }
        if (cablePath) {
            const source = this.map!.getSource("cablePathSource");
            if (source) {
                // @ts-ignore
                source.setData(cablePath);
                store.setCablePathVisible(this.$store, true);
            }
        }
        return;
    }

    async hideCablePath(): Promise<void> {
        const source = this.map!.getSource("cablePathSource");
        if (source) {
            // @ts-ignore
            source.setData(emptyFeature);
            store.setCablePathVisible(this.$store, false);
        }
        return;
    }

    createMapHash(): string {
        if (this.map) {
            const location = this.map.getCenter();
            const zoom = this.map.getZoom();
            const extraLayers: string[] = [];
            const hiddenLayers: string[] = [];
            for (const [layerName, layer] of Object.entries(this.layers)) {
                if (layer.visible && !this.defaultVisibility?.[layerName]) {
                    extraLayers.push(layerName);
                }
                if (!layer.visible && this.defaultVisibility?.[layerName]) {
                    hiddenLayers.push(layerName);
                }
            }
            const params = new URLSearchParams(
                [
                    ["lng", location.lng.toFixed(7)],
                    ["lat", location.lat.toFixed(7)],
                    ["z", zoom.toFixed(2)],
                    ["show", extraLayers.join("|")],
                    ["hide", hiddenLayers.join("|")],
                ].filter(val => !!val[1]),
            );
            return params.toString();
        }
        return "";
    }

    parseMapHash(): MapHashParameters {
        const params = new URLSearchParams(this.$route.hash.slice(1));
        let coords;
        if (params.has("lng") && params.has("lat")) {
            coords = new LngLat(parseFloat(params.get("lng")!), parseFloat(params.get("lat")!));
        }
        return {
            coords,
            zoom: params.has("z") ? parseFloat(params.get("z")!) : undefined,
            extraLayers: params.get("show")?.split("|"),
            hiddenLayers: params.get("hide")?.split("|"),
        };
    }

    onMoveEnd() {
        this.updateMapHash();
    }

    updateMapHash() {
        const newHash = this.createMapHash();
        // Note: $route.hash has '#' prefixed
        if (this.$route.hash.slice(1) !== newHash) {
            this.$router.replace({ query: this.$route.query, hash: newHash }).catch(noop);
        }
    }

    getDefaultVisibility(mapStyle: StyleConfig): { [key: string]: boolean } {
        const visibility: { [key: string]: boolean } = {};
        mapStyle.layers.map(layer => {
            visibility[layer.id] = layer.layout?.visibility === "visible" || layer.layout?.visibility === undefined;
        });
        return visibility;
    }

    get layerVisibility(): LayerVisibility[] {
        return Object.entries(this.layers).map(([name, layer]: [string, store.Layer]) => {
            return { name, visible: layer.visible };
        });
    }

    @Watch("layerVisibility")
    onLayerVisibilityChange(newVisibility: LayerVisibility[]): void {
        newVisibility.forEach(layer => {
            this.setLayerVisibility(layer.name, layer.visible);
        });
        this.updateMapHash();
    }

    setLayerVisibility(layerName: string, visible: boolean): void {
        if (this.map) {
            for (const layer of this.layers[layerName].layers) {
                this.map.setLayoutProperty(layer, "visibility", visible ? "visible" : "none");
            }
        }
    }

    /**
     * Returns the names of all currently visible layers
     */
    getVisibleLayers(): string[] {
        if (!this.map) {
            return [];
        }
        const visibleLayers = [];
        const zoom = this.map.getZoom();
        for (const layer of Object.values(this.layers)) {
            const isVisible = layer.layers.some(layerId => this.isMapboxLayerVisible(layerId, zoom));
            if (isVisible) {
                visibleLayers.push(layer.label);
            }
        }
        return visibleLayers;
    }

    /**
     * Returns true if a mapbox layer is visible at a given zoom level
     */
    isMapboxLayerVisible(layerId: string, zoomLevel: number): boolean {
        if (!this.map) {
            return false; // Map has not loaded
        }
        const visibility = this.map.getLayoutProperty(layerId, "visibility") === "visible";
        if (!visibility) {
            return false; // Layer is disabled
        }
        const layer = this.map.getLayer(layerId);
        if (layer.minzoom !== undefined && layer.minzoom > zoomLevel) {
            return false; // Map is zoomed too far out
        }
        if (layer.maxzoom !== undefined && layer.maxzoom < zoomLevel) {
            return false; // Map is zoomed too far in
        }
        return true;
    }

    async onClick(ev: mapboxgl.MapMouseEvent): Promise<void> {
        const drawMode = this.mapDraw?.getMode() ?? "simple_select";
        if (
            !this.map ||
            this.rulerControl?.isMeasuring ||
            this.locationToolControl?.active ||
            !this.config ||
            drawMode !== "simple_select"
        ) {
            return;
        }
        const coordinates = [ev.lngLat.lng, ev.lngLat.lat] as mapboxgl.LngLatLike;
        const dist = this.metersPerPixel(ev.lngLat.lat, this.map.getZoom()) * MAP_SELECT_TOLERANCE;
        let results = await this.getFeatureInfo(ev.lngLat.lng, ev.lngLat.lat, dist);
        // No results from network, query map tiles
        if (queryResultsLength(results) === 0) {
            const queryBox: [[number, number], [number, number]] = [
                [ev.point.x - MAP_SELECT_TOLERANCE, ev.point.y - MAP_SELECT_TOLERANCE],
                [ev.point.x + MAP_SELECT_TOLERANCE, ev.point.y + MAP_SELECT_TOLERANCE],
            ];
            const features = this.map
                .queryRenderedFeatures(queryBox)
                .filter(
                    feat => feat.sourceLayer && !feat.layer.id.endsWith("labels") && !feat.layer.id.endsWith("border"),
                );
            results = featuresToQueryResults(features, this.layers, this.config);
        }

        let designId: string | undefined;
        let cabinetName: string | undefined;
        if (results.Communities) {
            designId = results.Communities[0].properties.find(p => p.key === "design_uuid")?.value;
            cabinetName = results.Communities[0].properties.find(p => p.key === "name")?.value;
        }

        // Filter the results to visible layers
        const visibleLayers = this.getVisibleLayers();
        for (const key in results) {
            if (!visibleLayers.includes(key) || results[key].length === 0) {
                delete results[key];
            }
        }

        // Don't query Communities when zoom >= 12
        if (this.map.getZoom() >= 12 && results.Communities) {
            delete results.Communities;
        }
        const showPopout = (queryResults?: QueryResults, geometry?: GeoJSONGeometry) => {
            // Show a popup with the results
            if (!this.map) {
                return;
            }
            const token = accessTokenFromStore(this.$store);
            const enableEdit = this.mapDraw !== undefined && token && !isExternalUser(token);
            const popup = new maplibre.Popup()
                .setHTML(`<div id="vue-query-popup" />`)
                .setLngLat(coordinates)
                .addTo(this.map);
            const popupInstance = new QueryPopup({
                store: this.$store,
                vuetify,
                propsData: { results: queryResults, designId, cabinetName, geometry, enableEdit },
            });
            popupInstance.$mount("#vue-query-popup");
            popup._update();
        };
        if (queryResultsLength(results) === 0) {
            return;
        }

        showPopout(results);
    }

    /**
     * Returns the width in meters of a single pixel at a given latitude/zoom
     */
    metersPerPixel(latitude: number, zoomLevel: number): number {
        const earthCircumference = 40075017;
        const latitudeRadians = latitude * (Math.PI / 180);
        return (earthCircumference * Math.cos(latitudeRadians)) / Math.pow(2, zoomLevel + 8);
    }

    async getFeatureInfo(lng: number, lat: number, dist: number): Promise<QueryResults> {
        let results = {};
        const token = accessTokenFromStore(this.$store) ?? "";
        const client = await gigamapApiFactory(token);
        const featureLayers = ["statutory-designations", "nps-polygons", "pia-structures", "pia-ducts"];
        for (const layer of featureLayers) {
            if (this.map && this.isMapboxLayerVisible(layer, this.map.getZoom())) {
                try {
                    results = Object.assign(results, await wmsFeatureInfo(token, this.map.getStyle(), layer, lng, lat));
                } catch (error) {
                    // Catch network errors if we are offline
                    if (error instanceof TypeError) {
                        continue;
                    }
                }
            }
        }
        try {
            const resp = await client.query(lng, lat, dist, FEATURE_COUNT_RESULT_LIMIT);
            return Object.assign(results, resp.results);
        } catch (error) {
            return results;
        }
    }

    @Watch("searchResults")
    onSearchResultsUpdated(newResults?: QueryResults): void {
        if (!this.map || newResults === undefined || Object.keys(newResults).length === 0) {
            return;
        }
        this.removeMarkers();
        this.markers = [];

        const bounds = new LngLatBounds();
        for (const layerName in newResults) {
            if (newResults.hasOwnProperty(layerName)) {
                const features = newResults[layerName];
                bounds.extend(this.getBoundsForFeatures(features));
                for (const feature of features) {
                    const marker = new Marker().setLngLat(feature.geometry.coordinates).addTo(this.map);
                    this.markers.push(marker);
                }
            }
        }
        try {
            this.map.fitBounds(bounds, { maxZoom: 17, padding: 20 });
        } catch (e) {
            if (!(e instanceof TypeError)) {
                throw e;
            }
        }
    }

    addWayleavesHighlighter(feature: FeatureInfo): void {
        if (this.map) {
            const titleNo = feature.properties.find(obj => obj.key === "title_no")?.value;
            if (typeof titleNo === "string") {
                this.map.setFilter("wayleaves-highlighted", ["==", ["get", "title_no"], titleNo]);
                this.map.setFilter("wayleaves-highlighted-border", ["==", ["get", "title_no"], titleNo]);
            }
        }
    }

    @Watch("searchResultsVisible")
    onSearchResultsVisibleChange(newValue: boolean, oldValue: boolean): void {
        // When the search results panel is closed the markers should be removed
        if (newValue === false) {
            this.removeMarkers();
            this.removeWayleavesHighlighter();
        }
    }

    removeMarkers(): void {
        // Remove any existing markers
        if (this.markers) {
            for (const marker of this.markers) {
                marker.remove();
            }
        }
        this.markers = [];
    }

    removeWayleavesHighlighter(): void {
        this.map?.setFilter("wayleaves-highlighted", ["in", ["get", "title_no"], ["literal", []]]);
        this.map?.setFilter("wayleaves-highlighted-border", ["in", ["get", "title_no"], ["literal", []]]);
    }

    getBoundsForFeatures(features: FeatureInfo[]): LngLatBounds {
        const bounds = new LngLatBounds();
        for (const feature of features) {
            if (feature.bounds) {
                bounds.extend(feature.bounds);
            }
        }
        return bounds;
    }

    @Watch("gigastoreUploadDialogVisibleState")
    gigastoreUploadDialogVisibleStateChanged(value: boolean) {
        this.gigastoreUploadDialogVisible = value;
    }

    @Watch("gigastoreUploadDialogVisible")
    gigastoreUploadDialogVisibleChanged(value: boolean) {
        store.setGigastoreUploadDialogVisibility(this.$store, value);
        // Clean up after modal is closed
        if (!value) {
            store.setGigastoreUploadMarkerPosition(this.$store, undefined);
            this.gigastoreUploadMarkerDesignId = undefined;
        }
    }

    @Watch("gigastoreUploadMarkerPosition")
    async gigastoreUploadMarkerPositionChanged(value?: [number, number]) {
        if (!value) {
            return;
        }
        this.gigastoreUploadMarkerDesignId = await this.getDesignIdByLocation(value);
        if (!this.gigastoreUploadMarkerDesignId) {
            showSnackbar("Cannot add file - Location must be within a community");
            store.setGigastoreUploadMarkerPosition(this.$store, undefined);
        }
    }

    async getDesignIdByLocation(location: [number, number]): Promise<string | undefined> {
        if (!this.map) {
            return;
        }
        const dist = this.metersPerPixel(location[1], this.map.getZoom()) * MAP_SELECT_TOLERANCE;
        const results = await this.getFeatureInfo(location[0], location[1], dist);

        let designId: string | undefined;
        if (results.Communities) {
            designId = results.Communities[0].properties.find(p => p.key === "design_uuid")?.value;
        }
        return designId;
    }
    onDragStart() {
        this.map!.getCanvas().style.cursor = "move";
    }

    onDragEnd() {
        this.map!.getCanvas().style.cursor = this.rulerControl!.isMeasuring ? "crosshair" : "unset";
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-605fd1f7/map.tsx" });